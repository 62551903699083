import { sendGTMEvent } from '@next/third-parties/google';
import { NextWebVitalsMetric } from 'next/app';

import { DataLayerEvent } from './data-layer-event';

export function reportWebVitals({
  label,
  name,
  id,
  value,
}: NextWebVitalsMetric) {
  if (label === 'web-vital') {
    const event: DataLayerEvent = {
      event: 'web-vitals',
      event_category: 'Web Vitals',
      event_id: id,
      event_name: name,
      event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    };
    sendGTMEvent(event);
  }
  if (label === 'custom') {
    const event: DataLayerEvent = {
      event: 'next_js-metrics',
      event_category: 'Next.js Metrics',
      event_id: id,
      event_name: name,
      event_value: value,
    };
    sendGTMEvent(event);
  }
}
